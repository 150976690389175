import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import WebService from "../../Services/WebService";
import { useSelector } from "react-redux";
import TableLoader from "../Common/TableLoader/TableLoader";
import { toast } from "react-toastify";

const ReviewerSelector = (props) => {
  const { actionProvider } = props;
  const access_token = useSelector((state) => state.aT.aT);
  const t_id = useSelector((state) => state.tI.tenantId);
  const [reviewersList, setReviewersList] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [selectAllGroups, setSelectAllGroups] = useState(false);
  const [channelSelectErrFlag, setChannelSelectErrFlag] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getUsersList();
    getGroupsList();
  }, []);

  const getUsersList = () => {
    setLoader(true);
    return WebService.getAPI({
      action: `client/users?keyword=`,
      body: null,
      isShowError: true,
      access_token: access_token,
      t_id: t_id,
    })
      .then((res) => {
        setReviewersList(res);
      })
      .catch((error) => {
        setLoader(false);
        return error;
      });
  };

  const getGroupsList = () => {
    return WebService.getAPI({
      action: `groups?keyword=`,
      body: null,
      isShowError: true,
      access_token: access_token,
      t_id: t_id,
    })
      .then((res) => {
        setGroupsList(res);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        return error;
      });
  };

  const handleCheckboxChange = (value, checked, type) => {
    setChannelSelectErrFlag(false);

    if (type === "user") {
      setSelectedUsers((prevSelectedUsers) => {
        const updatedSelectedUsers = checked
          ? [...prevSelectedUsers, value]
          : prevSelectedUsers.filter((userId) => userId !== value);

        if (updatedSelectedUsers.length === reviewersList.length) {
          setSelectAllUsers(true);
        } else {
          setSelectAllUsers(false);
        }

        return updatedSelectedUsers;
      });
    } else if (type === "group") {
      setSelectedGroups((prevSelectedGroups) => {
        const updatedSelectedGroups = checked
          ? [...prevSelectedGroups, value]
          : prevSelectedGroups.filter((groupId) => groupId !== value);

        if (updatedSelectedGroups.length === groupsList.length) {
          setSelectAllGroups(true);
        } else {
          setSelectAllGroups(false);
        }

        return updatedSelectedGroups;
      });
    }
  };

  const handleSelectAll = (type, checked) => {
    if (type === "user") {
      setSelectAllUsers(checked);
      if (checked) {
        setSelectedUsers(reviewersList.map((user) => user.id));
      } else {
        setSelectedUsers([]);
      }
    } else if (type === "group") {
      setSelectAllGroups(checked);
      if (checked) {
        setSelectedGroups(groupsList.map((group) => group.id));
      } else {
        setSelectedGroups([]);
      }
    }
  };

  const handleSubmit = () => {
    const reviewers = {
      userList: selectedUsers.map((id) => ({ id })),
      groupList: selectedGroups.map((id) => ({ id })),
    };
    WebService.addLoader("channel-submit-btn");
    if (localStorage.getItem("btn") === "activity-btn") {
      submitActivityReport(reviewers);
    } else {
      setTimeout(() => {
        WebService.removeLoader("channel-submit-btn");
        actionProvider.handleReviewerListSubmit(
          reviewers,
          props?.payload?.startDate,
          props?.payload?.endDate
        );
      }, 2000);
      
    }
  };

  const submitActivityReport = (reviewers) => {      
    let obj = {
      "reviewers": reviewers,
      "startDate": props?.payload?.startDate,
      "endDate": props?.payload?.endDate,
      "type":"BOT"
    };    
    return WebService.postAPI({
      action: `report/activity/user/list/pdf`,
      body: obj,
      isShowError: true,
      access_token: access_token,
      t_id: t_id,
    })
      .then((res) => {
        toast.success(res);
        actionProvider.handleReviewersSubmit(
          reviewers,
          props?.payload?.startDate,
          props?.payload?.endDate
        );
        WebService.removeLoader("channel-submit-btn");
      })
      .catch((error) => {
        WebService.removeLoader("channel-submit-btn");
        return error;
      });
  };

  return (
    <div className="">
      {!loader ? (
        <div className="react-chatbot-kit-chat-bot-message">
          <div>
            <div>
              <span>
                <strong>Users List- </strong>
              </span>
              <br />
              <label style={{ color: "black" }}>
                <input
                  type="checkbox"
                  name="selectAllUsers"
                  checked={selectAllUsers}
                  onChange={(e) => handleSelectAll("user", e.target.checked)}
                />{" "}
                Select All
              </label>
              <br />
              {reviewersList.map((user) => (
                <div key={user.id}>
                  <label style={{ color: "black" }}>
                    <input
                      type="checkbox"
                      name="user"
                      checked={selectedUsers.includes(user.id)}
                      onChange={(e) =>
                        handleCheckboxChange(user.id, e.target.checked, "user")
                      }
                      value={user.id}
                    />{" "}
                    {user.first_name + " " + user.last_name}
                  </label>
                  <br />
                </div>
              ))}
            </div>
            <div>
              <span>
                <strong>Groups List-</strong>
              </span>
              <br />
              <label style={{ color: "black" }}>
                <input
                  type="checkbox"
                  name="selectAllGroups"
                  checked={selectAllGroups}
                  onChange={(e) => handleSelectAll("group", e.target.checked)}
                />{" "}
                Select All
              </label>
              <br />
              {groupsList.map((group) => (
                <div key={group.id}>
                  <label style={{ color: "black" }}>
                    <input
                      type="checkbox"
                      name="group"
                      checked={selectedGroups.includes(group.id)}
                      onChange={(e) =>
                        handleCheckboxChange(
                          group.id,
                          e.target.checked,
                          "group"
                        )
                      }
                      value={group.id}
                    />{" "}
                    {group.group_name}
                  </label>
                  <br />
                </div>
              ))}
            </div>
          </div>
          <Button
            id="channel-submit-btn"
            className="btn-brand-light mt-2"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      ) : (
        <TableLoader />
      )}
    </div>
  );
};

export default ReviewerSelector;
