import { createChatBotMessage } from 'react-chatbot-kit';
import CustomMessage from './CustomMessage';
import ChannelsSelector from './ChannelsSelector';
import TimeFrameSelector from './TimeFrameSelector';
import CustomHeader from './customeHeader';
import CustomAvatar from './customeAvtar';
import StartComplianceReviewButton from './StartComplianceReviewButton'; 
import SampleRateSelector from './SampleRateSelector'; 
import { Button } from 'react-bootstrap';
import FromDateSelector from './FromDate';
import ReviewerSelector from './ReviewersSelection';
import ReviewName from './ReviewName';

const botName = 'ExcitementBot';

const config = {
  initialMessages: [
    createChatBotMessage("Welcome to Archive Intel, How can I help you?", {
      widget: "startComplianceReviewButton"
    })
  ],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: '#376B7E',
    },
    chatButton: {
      backgroundColor: '#5ccc9d',
    },
  },
  customComponents: {
    header: (props) => <CustomHeader {...props} />,
    botAvatar: (props) => <CustomAvatar {...props} />,  
  },
  widgets: [
    {
      widgetName: "customMessage",
      widgetFunc: (props) => <CustomMessage {...props} />,
      props: {},
      mapStateToProps: ["messages"],
    },
    {
      widgetName: "channelsSelector",
      widgetFunc: (props) => <ChannelsSelector {...props} />,
      props: {},
      mapStateToProps: ["messages"],
    },
    {
      widgetName: "timeFrameSelector",
      widgetFunc: (props) => <TimeFrameSelector {...props} />,
      props: {},
      mapStateToProps: ["messages"],
    },
    {
      widgetName: "startComplianceReviewButton",
      widgetFunc: (props) => <StartComplianceReviewButton {...props} />,
      props: {},
      mapStateToProps: ["messages"],
    },
    {
      widgetName: "sampleRateSelector",
      widgetFunc: (props) => <SampleRateSelector {...props} />, 
      props: {},
      mapStateToProps: ["messages"],
    },
    {
      widgetName: "viewDataButton",
      widgetFunc: (props) => 
        <div className='react-chatbot-kit-chat-bot-message'>  
          <Button className='btn-brand-light mt-2'>View data</Button> 
        </div>,      
      props: {},
      mapStateToProps: ["messages"],
    },
    {
      widgetName: "fromDateSelector",
      widgetFunc: (props) => <FromDateSelector {...props} />,    
      props: {},
      mapStateToProps: ["messages"],
    },
    {
      widgetName: "reviewerSelector",
      widgetFunc: (props) => <ReviewerSelector {...props} />,    
      props: {},
      mapStateToProps: ["messages"],
    },
    {
      widgetName: "reviewName",
      widgetFunc: (props) => <ReviewName {...props} />,    
      props: {},
      mapStateToProps: ["messages"],
    },
  ],
};

export default config;




