import { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./AIPagination.scss"

interface PropData {
  totalItems: number;
  itemsCountPerPage?: number;
  className?: string;
  changePage: any;
  changeCurrentPage?: any;
  pagination?: any
}

const AIPagination = (props: PropData) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [pages, setPages] = useState<Number[]>([]);
  const [tempStatus, setTempStatus] = useState(false);

  useEffect(() => {
    if (!isNaN(props.changeCurrentPage)) {
      setCurrentPage(props.changeCurrentPage);
    }
  }, [props.changeCurrentPage]);
  useEffect(() => {
    var totalItems: number = props.totalItems ? props.totalItems : Number(100);
    var itemsCountPerPage: number = props.itemsCountPerPage
      ? props.itemsCountPerPage
      : Number(100);

    const totalPage = Math.ceil(totalItems / itemsCountPerPage);

    setLastPage(totalPage);
    var temp = [];
    let startPage, endPage;
    if (totalPage <= 5) {
      startPage = 1;
      endPage = totalPage;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPage) {
        startPage = totalPage - 4;
        endPage = totalPage;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }
    for (let i = startPage; i <= endPage; i++) {
      temp.push(i);
    }

    if (temp.length > 0) {
      setPages(temp);
    }
    setTimeout(() => {
      changePageEvent(currentPage);
    }, 100);
  }, [currentPage, props.totalItems]);

  function changePageEvent(page: any) {
    var clasEle = document.getElementsByClassName("currentPage");
    if (clasEle.length > 0) {
      for (let index = 0; index < clasEle.length; index++) {
        const element = clasEle[index];
        element.classList.remove("currentPage");
      }
    }
    document.getElementById("page_" + page)?.classList.add("currentPage");
  }

  const nextchangepage = () => {
    setCurrentPage(currentPage === lastPage ? lastPage : currentPage + 1);
    if (currentPage === lastPage) {
      props.changePage(currentPage);
    } else {
      props.changePage(currentPage + 1);
    }

    return setTempStatus((tempStatus) => !tempStatus);
  };
  const firstChangePage = () => {
    props.changePage(1);
  };
  const lastChangePage = () => {
    props.changePage(lastPage);
  };
  const changepage = (page: number) => {
    if (!isNaN(page)) {
      setCurrentPage(page);
      props.changePage(page);
    }
  };

  const previouschangepage = () => {
    setCurrentPage(currentPage === 1 ? currentPage : currentPage - 1);
    if (currentPage === 1) {
      props.changePage(currentPage);
    } else {
      props.changePage(currentPage - 1);
    }
    return setTempStatus((tempStatus) => !tempStatus);
  };

  return (
    <>
      {props.totalItems > props.pagination ? (
        <Pagination className={props.className}>
          {currentPage == 1 ? (
            ""
          ) : (
            <Pagination.First className='pagination-btn' onClick={firstChangePage} />
          )}
          {currentPage == 1 ? (
            ""
          ) : (
            <Pagination.Prev className='pagination-btn' onClick={() => previouschangepage()} />
          )}
          {pages.map((page: any, index: number) => {
            return (
              <div key={"Pagination_" + index}>
                <Pagination.Item
                  active={currentPage === page ? true : false}
                  onClick={() => changepage(page)}
                  id={"page_" + page}
                >
                  {page}
                </Pagination.Item>
              </div>
            );
          })}
          {currentPage == lastPage ? (
            ""
          ) : (
            <Pagination.Next className='pagination-btn' onClick={() => nextchangepage()} />
          )}
          {currentPage == lastPage ? (
            ""
          ) : (
            <Pagination.Last className='pagination-btn' onClick={lastChangePage} />
          )}
        </Pagination>
      ) : (
        ""
      )}
    </>
  );
};
export default AIPagination;
