import LoginImg from "../../assets/images/login-img.png";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import "./login.scss";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import WebService from "../../Services/WebService";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { aT } from "../../Redux/actions";


const inputs: any = document.getElementById("inputs");

inputs?.addEventListener("input", function (e: any) {
    const target = e.target;
    const val = target.value;

    if (isNaN(val)) {
        target.value = "";
        return;
    }

    if (val != "") {
        const next = target.nextElementSibling;
        if (next) {
            next.focus();
        }
    }
});

inputs?.addEventListener("keyup", function (e: any) {
    const target = e.target;
    const key = e.key.toLowerCase();

    if (key === "backspace" || key === "delete") {
        target.value = "";
        const prev = target.previousElementSibling;
        if (prev) {
            prev.focus();
        }
        return;
    }
});

const TwoFAOtp = () => {
    const t_id = useSelector((state: any) => state.tI.tenantId);
    const access_token = useSelector((state: any) => state.aT.aT);
    const dispatch = useDispatch();
    const role = useSelector((state: any) => state.uR.role);
    const [errorMsg, setErrorMsg] = useState<any>("");
    document.title = "Archive Intel Forgot Password";
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue
    } = useForm();
    const watchAllFields = watch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const state = useLocation();

    const handleChange = (e: any, val: any, index: any) => {
        if (e.keyCode !== "8") {
            setValue("otp_" + index, val);
            document.getElementById("otp_" + (parseInt(index) + 1))?.focus();
            setValue("otp_" + (parseInt(index) + 1), "");
        }
    };
    const handleBackChange = (e: any, index: any) => {
        if (e.keyCode == "8") {
            setValue("otp_" + (parseInt(index)), "");
            document.getElementById("otp_" + (parseInt(index) - 1))?.focus();
        }
    };


    const handleVerifyOtp = () => {
        setErrorMsg("")
        localStorage.setItem("user_access_token", state?.state?.access_token);
        dispatch(aT(state?.state?.access_token));

        var otp = "";
        otp = watchAllFields.otp_1 + watchAllFields.otp_2 + watchAllFields.otp_3 + watchAllFields.otp_4 + watchAllFields.otp_5 + watchAllFields.otp_6;
        if (otp.length < 6) {
            setErrorMsg("Please enter valid OTP")
            return false
        }
        WebService.addLoader("btn-verify");
        return WebService.getAPI({
            action: `setting/verify-code?code=${otp}`,
            body: null,
            isShowError: true,
            access_token:access_token,
            t_id:t_id
        })
            .then((res: any) => {
                toast.success(res.msg)
                WebService.removeLoader("btn-verify");
                if (window.atob(localStorage.getItem('uat') ?? "") === "COMPLIANCE_OFFICER") {
                    navigate("/my-clients");
                } else {
                    if (role === "BASIC_USER") {
                        // navigate("/connector")
                        navigate("/dashboard");
                    } if (role === "COMPLIANCE_OFFICER") {
                        navigate("/my-clients")
                    } else {
                        navigate("/dashboard");
                    }
                }
            })
            .catch((error) => {
                localStorage.removeItem("user_access_token");
                WebService.removeLoader("btn-verify");
                toast.error(error?.response?.data?.message);
                return error;
            });
    };
    const handleError = () => { };


    return (
        <>
            <div className="login-page">
                <div className="row g-0">
                    <div className="col-lg-7">
                        <img src={LoginImg} className="login-img" alt="" />
                    </div>
                    <div className="col-lg-5 d-flex align-items-center justify-content-center">
                        <div className="w-100">
                            <Row className=" justify-content-center">
                                <Col lg={9}>
                                    <Card>
                                        <Card.Header className=" text-center bg-white py-3">
                                            <h2 className="font-22 mb-0 font-bold">Two-Factor Authentication</h2>
                                        </Card.Header>
                                        <Card.Body className=" text-center">
                                            <p>Enter OTP</p>
                                            <Form
                                                name="Verify"
                                                id="Verify"
                                                onSubmit={handleSubmit(handleVerifyOtp)}
                                            >
                                                <Row className=" justify-content-center">
                                                    <Col lg={12}>
                                                        <div className="d-flex gap-3 otp-controlls mb-3" id="inputs">
                                                            <Form.Control
                                                                type="text"
                                                                id="otp_1"
                                                                {...register("otp_1", {
                                                                    required: "Please enter OTP",
                                                                    pattern: {
                                                                        value:
                                                                            /^[0-9]{1}$/i,
                                                                        message: "Please enter valid OTP",
                                                                    },
                                                                })}
                                                                minLength={1}
                                                                maxLength={1}
                                                                className="form-control text-center"
                                                                onKeyUp={(event) => {
                                                                    handleBackChange(event, "1");
                                                                }}
                                                                onChange={(event) => {
                                                                    handleChange(event, event.target.value, "1");
                                                                }}
                                                            />
                                                            <Form.Control
                                                                id="otp_2"
                                                                type="text"
                                                                {...register("otp_2", {
                                                                    required: "Please enter OTP",
                                                                    pattern: {
                                                                        value:
                                                                            /^[0-9]{1}$/i,
                                                                        message: "Please enter valid OTP",
                                                                    },
                                                                })}
                                                                minLength={1}
                                                                maxLength={1}
                                                                className="form-control text-center"
                                                                onKeyUp={(event) => {
                                                                    handleBackChange(event, "2");
                                                                }}
                                                                onChange={(event) => {
                                                                    handleChange(event, event.target.value, "2");
                                                                }}
                                                            />
                                                            <Form.Control
                                                                id="otp_3"
                                                                type="text"
                                                                {...register("otp_3", {
                                                                    required: "Please enter OTP",
                                                                    pattern: {
                                                                        value:
                                                                            /^[0-9]{1}$/i,
                                                                        message: "Please enter valid OTP",
                                                                    },
                                                                })}
                                                                minLength={1}
                                                                maxLength={1}
                                                                className="form-control text-center"
                                                                onKeyUp={(event) => {
                                                                    handleBackChange(event, "3");
                                                                }}
                                                                onChange={(event) => {
                                                                    handleChange(event, event.target.value, "3");
                                                                }}
                                                            />
                                                            <Form.Control
                                                                id="otp_4"
                                                                type="text"
                                                                {...register("otp_4", {
                                                                    required: "Please enter OTP",
                                                                    pattern: {
                                                                        value:
                                                                            /^[0-9]{1}$/i,
                                                                        message: "Please enter valid OTP",
                                                                    },
                                                                })}
                                                                minLength={1}
                                                                maxLength={1}
                                                                className="form-control text-center"
                                                                onKeyUp={(event) => {
                                                                    handleBackChange(event, "4");
                                                                }}
                                                                onChange={(event) => {
                                                                    handleChange(event, event.target.value, "4");
                                                                }}
                                                            />
                                                            <Form.Control
                                                                id="otp_5"
                                                                type="text"
                                                                {...register("otp_5", {
                                                                    required: "Please enter OTP",
                                                                    pattern: {
                                                                        value:
                                                                            /^[0-9]{1}$/i,
                                                                        message: "Please enter valid OTP",
                                                                    },
                                                                })}
                                                                minLength={1}
                                                                maxLength={1}
                                                                className="form-control text-center"
                                                                onKeyUp={(event) => {
                                                                    handleBackChange(event, "5");
                                                                }}
                                                                onChange={(event) => {
                                                                    handleChange(event, event.target.value, "5");
                                                                }}
                                                            />
                                                            <Form.Control
                                                                id="otp_6"
                                                                type="text"
                                                                {...register("otp_6", {
                                                                    required: "Please enter OTP",
                                                                    pattern: {
                                                                        value:
                                                                            /^[0-9]{1}$/i,
                                                                        message: "Please enter valid OTP",
                                                                    },
                                                                })}
                                                                minLength={1}
                                                                maxLength={1}
                                                                className="form-control text-center"
                                                                onKeyUp={(event) => {
                                                                    handleBackChange(event, "6");
                                                                }}
                                                                onChange={(event) => {
                                                                    handleChange(event, event.target.value, "6");
                                                                }}
                                                            />
                                                        </div>
                                                        <span className="text-danger font-14">{errorMsg}</span>
                                                        <div className="text-center">
                                                            <Button type="submit" className="btn btn-brand-1" id={"btn-verify"}>Verify</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                        {/* <Link to="" className="btn btn-brand-light help-btn">
                            {t("Forgot_Password.Need_Help")}?
                        </Link> */}
                    </div>
                </div>
            </div>
        </>
    );
};
export default TwoFAOtp;
