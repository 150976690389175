import { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import WebService from "../../Services/WebService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface propsData {
    socialType?: any;
    filterObject?: any;
    parentcallback?: any;
    list?: any;
    reviewId?: any;
}

const ReviewNamePopup = (props: propsData) => {
    const navigate = useNavigate();
    const t_id = useSelector((state: any) => state.tI.tenantId);
    const access_token = useSelector((state: any) => state.aT.aT);
    const [reviewName, setReviewName] = useState<any>("");
    const [reviewNameErr, setReviewNameErr] = useState<any>("");

    const handleSaveReviewName = () => {
        if (reviewName.trim() === "" || reviewName === null || reviewName === undefined) {
            setReviewNameErr("Please enter review name");
            return false;
        }
        WebService.addLoader("save-btn");
        var arr: any[] = [];
        props.list?.map((ele: any) => {
            arr.push(ele.id)
        })

        var obj = {
            "reviewName": reviewName,
            "reviewId": props.reviewId
        }

        return WebService.postAPI({
            action: `create-review`,
            body: obj,
            isShowError: true,
            access_token: access_token,
            t_id: t_id
        })
            .then((res: any) => {
                toast.success(res.message);
                props.parentcallback(false);
                WebService.removeLoader("save-btn");
                navigate(`/review/review-all/${props.reviewId}`);
            })
            .catch((error: any) => {
                WebService.removeLoader("save-btn");
                return error;
            });

    }

    return (
        <>
            <div className=" form-style">
                <div className="px-3 mt-3">
                    <div className="mt-3">
                        <FloatingLabel
                            controlId="reviewName"
                            label="Review Name"
                            className=""
                        >
                            <Form.Control
                                type="text"
                                onChange={(e: any) => {
                                    setReviewNameErr("");
                                    setReviewName(e.target.value);
                                }}
                                placeholder="Review Name"
                            />
                        </FloatingLabel>
                    </div>
                    <span className="text-danger font-12">{reviewNameErr}</span>
                    <div className="mt-3 mb-2">
                        <Button
                            type="button"
                            className="btn btn-brand-1 w-100"
                            id="save-btn"
                            onClick={() => handleSaveReviewName()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReviewNamePopup;




