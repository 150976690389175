
import React from 'react';

const CustomAvatar = () => {
  return (
    <div className='react-chatbot-kit-chat-bot-avatar'>
      <div className='react-chatbot-kit-chat-bot-avatar-container'>
          <div className='react-chatbot-kit-chat-bot-avatar-letter'>
              AI
          </div>
      </div>
    </div>
  );
};

export default CustomAvatar;
