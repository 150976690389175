import { toast } from "react-toastify";

import {
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { Button } from "react-bootstrap";
import WebService from "../../../Services/WebService";

interface propsData {
  onSuccess: any
  onFailed: any
}



const StripeForm = (props: propsData) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    WebService.addLoader("btn-submit");
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "",
      },
      redirect: 'if_required'
    });

    if (result.error) {
      WebService.removeLoader("btn-submit");
      toast.error(result.error.message);
      props.onFailed(result.error)
    } else {
      WebService.removeLoader("btn-submit");
      props.onSuccess(result)
    }
  };



  return <form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
    <>
      <PaymentElement />
      <Button variant="primary" className="btn-brand-1 mt-1" type="submit" id="btn-submit">Submit</Button>
    </>
  </form>
    ;
};

export default StripeForm;
