import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import WebService from '../../Services/WebService';

const SampleRateSelector = (props) => {
  const { actionProvider } = props;
  const [selectedRate, setSelectedRate] = useState('');

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedRate(prevSelectedRate => (prevSelectedRate === value ? '' : value));
  };
  

  const handleSubmit = () => {
    WebService.addLoader("rate-submit-btn");
    setTimeout(() => {
      WebService.removeLoader("rate-submit-btn");
      actionProvider.handleSampleRateSubmit(selectedRate,props?.payload?.reviewName);
    }, 2000);
  };

  return (
    <div className='react-chatbot-kit-chat-bot-message'>  
      <div>
        <label style={{color:"black"}}>
          <input
            type="radio"
            value="5"
            checked={selectedRate === "5"}
            onChange={handleRadioChange}
          /> 5%
        </label>
      </div>
      <div>
        <label style={{color:"black"}}>
          <input
            type="radio"
            value="10"
            checked={selectedRate === "10"}
            onChange={handleRadioChange}
          /> 10%
        </label>
      </div>
      <div>
        <label style={{color:"black"}}>
          <input
            type="radio"
            value="15"
            checked={selectedRate === "15"}
            onChange={handleRadioChange}
          /> 15%
        </label>
      </div>
      <div>
        <label style={{color:"black"}}>
          <input
            type="radio"
            value="20"
            checked={selectedRate === "20"}
            onChange={handleRadioChange}
          /> 20%
        </label>
      </div>
      <div>
        <label style={{color:"black"}}>
          <input
            type="radio"
            value="25"
            checked={selectedRate === "25"}
            onChange={handleRadioChange}
          /> 25%
        </label>
      </div>
      <div>
        <input
         type = "number"
         onChange={(e)=>{
          setSelectedRate(e.target.value);
         }}
         defaultValue={selectedRate}
         >
        </input>
      </div>
      <Button id="rate-submit-btn" className='btn-brand-light mt-2' onClick={handleSubmit} >
        Submit
      </Button>
    </div>
  );
};

export default SampleRateSelector;
