import React from 'react';
import aiimage from '../../assets/images/ai-logo.png';

const CustomMessage = () => {
  return (
    <img
      src={aiimage}
      alt="AI Logo"
      style={{ width: '50%' }}
    />
  );
};

export default CustomMessage;
