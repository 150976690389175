import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import WebService from "../../Services/WebService";
import AIDatePicker from "../Common/AIDatePicker/AIDatePicker";

const FromDateSelector = (props) => {
  const { actionProvider, btnType } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  ;
  useEffect(() => {
  }, [btnType]);

  const onDateChange = (date, type) => {
    if (type === "startDate") {
      setStartDate(date);
    } else if (type === "endDate") {
      setEndDate(date);
    }
  };

  const handleSubmit = () => {
    WebService.addLoader("date-range-submit-btn");
    if (props?.payload?.btnType !== "Custom") {
      setTimeout(() => {
        WebService.removeLoader("date-range-submit-btn");
        actionProvider.handleReviewersSelection(startDate, endDate);
      }, 2000);
    }else{ 
      setTimeout(() => {
        WebService.removeLoader("date-range-submit-btn");      
        actionProvider.handleStartReview(startDate,endDate, props?.payload?.sample_rate);
      }, 2000);

    }
  };

  return (
    <div className="react-chatbot-kit-chat-bot-message">
      <span> From Date :</span>
      <AIDatePicker
        date={startDate}
        selectDate={(date) => onDateChange(date, "startDate")}
        maxDate={new Date()}
        isShowTop={true}
      />
      <span> To Date :</span>
      <AIDatePicker
        date={endDate}
        selectDate={(date) => onDateChange(date, "endDate")}
        maxDate={new Date()}
        isShowTop={true}
      />
      <Button
        id="date-range-submit-btn"
        className="btn-brand-light mt-2"
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </div>
  );
};

export default FromDateSelector;
