import { Button, FloatingLabel, Form, Offcanvas, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useRef, useState } from "react";
import { Label } from "../../Common/Label/Label";
import AIDatePicker from "../../Common/AIDatePicker/AIDatePicker";
import moment from "moment";
import { useSelector } from "react-redux";
interface propsData {
  sendData: any;
  parentCallback: any;
  isFromReviewAll?: any;
  isFromEscalated?: any
}
const FilterBlade = (props: propsData) => {
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  const mobileNoRef = useRef<any>();
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset
  } = useForm();
  var watchVariable = watch();
  const [selectedMobile, setSelectedMobile] = useState<any[]>(props.parentCallback?.accounts);
  const [startDate, setStartDate] = useState<any>(props?.parentCallback?.startDate !== undefined
    ? props?.parentCallback?.startDate
    : new Date());
  const [endDate, setEndDate] = useState<any>(props?.parentCallback?.endDate !== undefined
    ? props?.parentCallback?.endDate
    : new Date());
  const [keywords, setKeywords] = useState<any>(props.parentCallback?.keywords);
  const [notes, setNotes] = useState<any>(props.parentCallback?.notes);
  const [reviews, setReviews] = useState<any[]>([]);
  const [startDateFlag, setStartDateFlag] = useState(false);
  const [endDateFlag, setEndDateFlag] = useState(false);
  const [checkbox, setCheckbox] = useState({
    markedAsFlag: props.parentCallback?.markedAsFlag || undefined,
    markedAsView: props.parentCallback?.markedAsView || undefined,
    escalateAndSend: props.parentCallback?.escalateAndSend || undefined,
    flaggedNotReviewed: props.parentCallback?.flaggedNotReviewed || undefined,
    notReviewed: props.parentCallback?.notReviewed || undefined,

  });
  const [error, setError] = useState<any>("");
  const [senders, setSenders] = useState<any>(props?.parentCallback?.sender);
  const [receivers, setReceivers] = useState<any>(
    props?.parentCallback?.recipient
  );
  const [percentage, setPercentage] = useState<any>(
    props?.parentCallback?.percentage
  );

  const handleCheckboxChange = (key: any, e?: any) => {
    setCheckbox((prevWatchVariable: any) => ({
      ...prevWatchVariable,
      [key]: prevWatchVariable[key] == undefined ? true : undefined,
    }));
    setValue(key, e)
  };

  const handleFilterApply = () => {
    if (error !== "") {
      return false;
    }
    if (moment(startDate).isAfter(endDate, 'day')) {
      setEndDateFlag(true);
      return;
    } else {
      setEndDateFlag(false);
    }
    var obj = {
      sender:
        senders?.length === 1
          ? senders[0] !== ""
            ? senders
            : undefined
          : senders,
      recipient:
        receivers?.length === 1
          ? receivers[0] !== ""
            ? receivers
            : undefined
          : receivers,
      accounts: selectedMobile,
      startDate: startDate,
      endDate: endDate,
      keywords: keywords,
      notes: notes,
      percentage: (props.isFromReviewAll || props.isFromEscalated) ? undefined : percentage !== undefined ? parseInt(percentage) : 100,
      markedAsFlag: checkbox?.markedAsFlag,
      markedAsView: checkbox?.markedAsView,
      escalateAndSend: checkbox?.escalateAndSend,
      flaggedNotReviewed: checkbox?.flaggedNotReviewed,
      notReviewed: checkbox?.notReviewed,
    };

    props.sendData(obj, "FILTER");

  };
  const handleError = (error: any) => {

  };

  const resetFilter = () => {
    props.sendData({})
    mobileNoRef?.current?.clearValue();
    setStartDate("");
    setEndDate("");
    setKeywords("");
    setNotes("");
    setCheckbox({
      markedAsFlag: undefined,
      markedAsView: undefined,
      escalateAndSend: undefined,
      flaggedNotReviewed: undefined,
      notReviewed: undefined
    });
  };

  const handleSendersChange = (e: any) => {
    setError("");
    const values = e.target.value.split(",").map((value: any) => value.trim());
    setSenders(values);
  };
  const handleReceiversChange = (e: any) => {
    setError("");
    const values = e.target.value.split(",").map((value: any) => value.trim());
    setReceivers(values);
  };


  return (
    <>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Filters</Offcanvas.Title>
      </Offcanvas.Header>
      <Form
        className="w-100 form-style"
        name="Verify"
        id="Verify"
        onSubmit={handleSubmit(handleFilterApply, handleError)}
      >
        <Offcanvas.Body className="px-0 form-style ">
          <div className="px-3 mt-3">
            <div className="mt-1 mb-3">
              <FloatingLabel
                controlId="senders"
                label="Senders"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  onChange={handleSendersChange}
                  placeholder="Senders"
                  value={senders}
                />
              </FloatingLabel>
            </div>
            <div className="mt-3 mb-3">
              <FloatingLabel
                controlId="receiver"
                label="Receivers"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  onChange={handleReceiversChange}
                  placeholder="Receivers"
                  value={receivers}
                />
              </FloatingLabel>
            </div>

            <Row>
              <Col>
                <AIDatePicker
                  date={startDate}
                  selectDate={(date: any) => {
                    setStartDate(date);
                  }}
                  maxDate={new Date()}
                />
                {startDate === "" && startDateFlag ? (
                  <Label
                    title="Please select start date as well"
                    modeError={true}
                    showStar={true}
                    type=""
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                <AIDatePicker
                  date={endDate}
                  selectDate={(date: any) => {
                    setEndDate(date);
                  }}
                  maxDate={new Date()}
                />
                {endDate === "" && endDateFlag ? (
                  <Label
                    title="Please select end date as well"
                    modeError={true}
                    showStar={true}
                    type=""
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>

            <div className="mb-3">
              <FloatingLabel
                controlId="Keywords"
                label="Keywords"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  onChange={(e: any) => {
                    setKeywords(e.target.value);
                  }}
                  placeholder="Keywords"
                  value={keywords}
                />
              </FloatingLabel>
            </div>

            {
              props.isFromEscalated || props.isFromReviewAll ?
                "" :
                <div className="">
                  <div className="mt-1 mb-3">
                    <FloatingLabel
                      controlId="Percentage"
                      label="Review Percentage"
                      className="mb-3"
                    >
                      <Form.Control
                        type="number"
                        onChange={(e: any) => {
                          const value = parseInt(e.target.value);
                          if (value <= 100) {
                            setError("");
                            setPercentage(value);
                          } else {
                            setError("Percentage cannot be greater than 100");
                          }
                        }}
                        placeholder="Review Percentage"
                        defaultValue={percentage}
                      />
                    </FloatingLabel>
                  </div>
                  <span className="text-danger font-12">{error}</span>
                </div>
            }
          </div>

          <hr className=" border-secondary-subtle" />
          <div className="px-4 mb-2">
            <div className="mb-1 text-nowrap">
              <Row className="row-cols-4 mb-2 d-flex gap-2" >
                <div className="px-2 py-1 bg-danger-subtle">
                  <Form.Check
                    type="checkbox"
                    id="markedAsFlag-whatsapp"
                    label="Flagged"
                    className="labe-text-dark checkbox-danger"
                    checked={checkbox.markedAsFlag}
                    onChange={(event) => handleCheckboxChange('markedAsFlag', event?.target?.checked)}
                  />
                </div>
                <div className="px-2 py-1 bg-success-subtle">
                  <Form.Check
                    type="checkbox"
                    id="markedAsView-whatsapp"
                    label="Reviewed"
                    className="labe-text-dark checkbox-success"
                    checked={checkbox.markedAsView}
                    onChange={(event) => handleCheckboxChange('markedAsView', event?.target?.checked)}
                  />
                </div>
                <div className="px-2 py-1 bg-warning-subtle">
                  <Form.Check
                    type="checkbox"
                    id="escalateAndSend-whatsapp"
                    label="Escalated"
                    className="labe-text-dark checkbox-warning"
                    checked={checkbox.escalateAndSend}
                    onChange={(event) => handleCheckboxChange('escalateAndSend', event?.target?.checked)}
                  />
                </div>
              </Row>
              <Row className="mb-2 d-flex gap-2">
                <div className="px-2 py-1 bg-primary-subtle col-6 text-nowrap">
                  <Form.Check
                    type="checkbox"
                    className="labe-text-dark checkbox-primary"
                    id="ReviewedEmails1-whatsapp"
                    label="Flagged but not reviewed"
                    checked={checkbox.flaggedNotReviewed}
                    onChange={(event) =>
                      handleCheckboxChange("flaggedNotReviewed", event?.target?.checked)
                    }
                  />
                </div>
                <div className="px-2 py-1 bg-secondary-subtle col-4 text-nowrap">
                  <Form.Check
                    type="checkbox"
                    className="labe-text-dark checkbox-primary"
                    id="ReviewedEmails2-whatsapp"
                    label="Not yet reviewed"
                    checked={checkbox.notReviewed}
                    onChange={(event) =>
                      handleCheckboxChange("notReviewed", event?.target?.checked)
                    }
                  />
                </div>
              </Row>
            </div>
          </div>
          <hr className=" border-secondary-subtle" />
          <div className=" form-style">
            <div className="px-3">
              <div className="mt-3 mb-2">
                <Button
                  type="submit"
                  className="btn btn-brand-1 w-100"
                  id="apply"
                >
                  Apply
                </Button>
              </div>
              <Col className="item-align-end font-12" style={{ "textAlign": "end" }}>
                <a
                  onClick={() => resetFilter()}
                  className="font-14 cursor-pointer"
                >
                  Reset Filter
                </a>
              </Col>
            </div>
          </div>
        </Offcanvas.Body>
      </Form>
    </>
  );
};
export default FilterBlade;
